import React from "react"
import Layout from 'gatsby-theme-carbon/src/templates/Default';

const frontmatter = {
  label: 'Payment Receipt',
  title: 'Payment Receipt',
  description: 'Payment Receipt',
};

/**
 * @param {Object} location is destructured from the internal gatsby navigation api
 */
const PaymentReceipt = ({ location, pageContext, ...rest  }) => {
  // destructures the state passed via the payment form component
  const { state } = location
  return (
    <Layout pageContext={{ ...pageContext, frontmatter }} location={{...location}} {...rest}>
      <>
        <h1>
          Your payment was successful!
        </h1>
        <h2>
        {!state ? (
          <>No payment information is present.</>
        ) : (
           //<pre>{JSON.stringify(state.paymentInfo, null, 2)}</pre>
          <a href={state.paymentInfo.payment.receipt_url}  target="_blank" rel="noopener noreferrer">Here's your payment receipt information</a>
        )}
        </h2>
      </>
    </Layout>
  )
}
export default PaymentReceipt
